import _slicedToArray from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { usePackageIndex } from '@aftersale-next/atoms';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
export function usePackageIndexAutoSetupEffect(packageIndexParam) {
  var router = useRouter();

  var _usePackageIndex = usePackageIndex(),
      _usePackageIndex2 = _slicedToArray(_usePackageIndex, 2),
      setPackageIndex = _usePackageIndex2[1];

  var package_index = router.query.package_index;
  useEffect(function () {
    if (true && (!!package_index || !!packageIndexParam)) {
      setPackageIndex(packageIndexParam ? parseInt(packageIndexParam) : parseInt(package_index));
    }
  }, [package_index, packageIndexParam, setPackageIndex]);
}