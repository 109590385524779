import _slicedToArray from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useIsEmbed } from '@aftersale-next/atoms';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
export function useEmbedAutoSetupEffect(embedParam) {
  var router = useRouter();

  var _useIsEmbed = useIsEmbed(),
      _useIsEmbed2 = _slicedToArray(_useIsEmbed, 2),
      setIsEmbed = _useIsEmbed2[1];

  var embed = router.query.embed;
  useEffect(function () {
    if (!window) return;
    var isEmbed = (embedParam !== null && embedParam !== void 0 ? embedParam : embed) === 'true';
    setIsEmbed(isEmbed);
  }, [embedParam, embed, setIsEmbed]);
}