export function shouldRedirectToChannels(data) {
  var _data$tracking, _data$tracking$isNoti, _data$tracking2, _data$tracking$channe, _data$tracking3;

  var chosenChannel = (_data$tracking = data.tracking) === null || _data$tracking === void 0 ? void 0 : _data$tracking.channels.find(function (ch) {
    return ch.enabled;
  });
  var isNotificationActive = (_data$tracking$isNoti = (_data$tracking2 = data.tracking) === null || _data$tracking2 === void 0 ? void 0 : _data$tracking2.isNotificationActive) !== null && _data$tracking$isNoti !== void 0 ? _data$tracking$isNoti : true;
  var availableChannels = (_data$tracking$channe = (_data$tracking3 = data.tracking) === null || _data$tracking3 === void 0 ? void 0 : _data$tracking3.channelList.filter(function (ch) {
    return ch.enabled;
  })) !== null && _data$tracking$channe !== void 0 ? _data$tracking$channe : [];
  return !chosenChannel && availableChannels.length > 0 && isNotificationActive;
}