import _slicedToArray from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useOrder } from '@aftersale-next/atoms';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
export function useOrderAutoSetupEffect(orderParam) {
  var router = useRouter();

  var _useOrder = useOrder(),
      _useOrder2 = _slicedToArray(_useOrder, 2),
      setOrder = _useOrder2[1];

  var order = router.query.order;
  useEffect(function () {
    if (true && (!!order || !!orderParam)) {
      var _ref;

      setOrder((_ref = orderParam) !== null && _ref !== void 0 ? _ref : order);
    }
  }, [order, orderParam, setOrder]);
}