import _slicedToArray from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useConfirmation } from '@aftersale-next/atoms';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
export function useConfirmationAutoSetupEffect(confirmationParam) {
  var router = useRouter();

  var _useConfirmation = useConfirmation(),
      _useConfirmation2 = _slicedToArray(_useConfirmation, 2),
      setConfirmation = _useConfirmation2[1];

  var confirmation = router.query.confirmation;
  useEffect(function () {
    if (true && (!!confirmation || !!confirmationParam)) {
      setConfirmation(confirmationParam !== null && confirmationParam !== void 0 ? confirmationParam : confirmation);
    }
  }, [confirmation, confirmationParam, setConfirmation]);
}