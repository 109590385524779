import { loginWithDocumentValidation, loginWithEmailValidation, loginWithoutConfirmation } from '../validation/login-schemas';
export var selectEcommerceLoginValidation = function selectEcommerceLoginValidation(confirmationType, t) {
  switch (confirmationType) {
    case 'email':
      return loginWithEmailValidation(t);

    case 'document':
      return loginWithDocumentValidation(t);

    default:
      return loginWithoutConfirmation(t);
  }
};