import _slicedToArray from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { ampli } from '@aftersale-next/ampli';
import { useEcommerceName, useIsEmbed } from '@aftersale-next/atoms';
import { AMPLI_SOURCE_ENV } from 'apps/customer/config/env';
import { useRouter } from 'next/router';
import { createTrackingUrl } from '../../utils/create-url';
export var useGoToTracking = function useGoToTracking() {
  var _useRouter = useRouter(),
      push = _useRouter.push,
      query = _useRouter.query;

  var _useIsEmbed = useIsEmbed(),
      _useIsEmbed2 = _slicedToArray(_useIsEmbed, 1),
      isEmbed = _useIsEmbed2[0];

  var _useEcommerceName = useEcommerceName(),
      _useEcommerceName2 = _slicedToArray(_useEcommerceName, 1),
      name = _useEcommerceName2[0];

  var pushToTracking = function pushToTracking(params) {
    var order = params.order,
        confirmation = params.confirmation,
        packageIndex = params.packageIndex,
        embed = params.embed,
        document = params.document;
    ampli.identify(document !== null && document !== void 0 ? document : undefined, {
      UserType: 'Consumidor'
    });
    ampli.viewTrackingInterface({
      OrderId: order,
      EcommerceName: name,
      Enviroment: AMPLI_SOURCE_ENV
    });
    return push(createTrackingUrl({
      order: order,
      confirmation: confirmation,
      packageIndex: packageIndex ? String(packageIndex) : query.package_index,
      isEmbed: embed !== null && embed !== void 0 ? embed : isEmbed,
      csatRate: query.csat_rate
    }));
  };

  return pushToTracking;
};