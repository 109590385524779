import _slicedToArray from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useIsEmbed } from '@aftersale-next/atoms';
import { useRouter } from 'next/router';
import { createChannelUrl } from '../../utils/create-url';
export var useGoToChannel = function useGoToChannel() {
  var _useRouter = useRouter(),
      push = _useRouter.push,
      query = _useRouter.query;

  var _useIsEmbed = useIsEmbed(),
      _useIsEmbed2 = _slicedToArray(_useIsEmbed, 1),
      isEmbed = _useIsEmbed2[0];

  var pushToChannel = function pushToChannel(order, confirmation, embed) {
    return push(createChannelUrl({
      order: order,
      confirmation: confirmation,
      packageIndex: query.package_index,
      isEmbed: embed !== null && embed !== void 0 ? embed : isEmbed,
      csatRate: query.csat_rate
    }));
  };

  return pushToChannel;
};