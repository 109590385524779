import * as yup from 'yup';
export var loginWithEmailValidation = function loginWithEmailValidation(t) {
  return yup.object().shape({
    order: yup.string().required(t('errors.order_is_required')),
    confirmation: yup.string().required(t('errors.order_email_is_required')).email(t('errors.order_email_is_invalid'))
  });
};
export var loginWithDocumentValidation = function loginWithDocumentValidation(t) {
  return yup.object().shape({
    order: yup.string().min(1).required(t('errors.order_is_required')),
    confirmation: yup.string().required(t('errors.order_document_is_required')).matches(/^([\d]{11})|([\d]{14})$/, t('errors.order_document_is_invalid'))
  });
};
export var loginWithoutConfirmation = function loginWithoutConfirmation(t) {
  return yup.object().shape({
    order: yup.string().required(t('errors.order_is_required'))
  });
};